import '../../styles/src/universal.scss';

// jQuery settings
import jquery from 'jquery';
window.jQuery = jquery;
window.$ = jquery;

// Global JS imports and settings
import './vendor/lazysizes.js';
import 'simplebar';
import 'slick-carousel';
import './vendor/smoothscroll-polyfill.js';
